﻿.link {
	display: inline-block;
	position: relative;
	padding: 0 rem(12px) 0 0;
	color: $color-primary;

	&:before {
		@include icon($icon-arrow-right);
		position: absolute;
		font-size: rem(8px);
		top: rem(11px);
		right: 0;
		transition: all 250ms ease-in-out 0s;
	}

	&:hover:before {
		right: -2px;
	}

	&.-bold {
		font-weight: 600;
	}

	&.-secondary {
		color: $color-secondary;
	}

	&.-small {
		@include font-small;
		padding-top: rem(6px);
		opacity: 0.6;
	}
}
