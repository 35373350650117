﻿.image {
	&__container {
		overflow: hidden;
		width: 100%;
		margin: 0 0 rem(21px);

		img {
			width: 100%;
			height: auto;
		}

		@include bp-min(s) {
			float: right;
			margin: rem(10px) 0 rem(13px) rem(34px);
		}

		@include bp-min(xxl) {
			max-width: rem(460px);
		}

		&.-fixed {
			@include responsive-container-pseudo(600, 400);
		}

		&.-full-width {
			@include responsive-container-pseudo(920, 416);
			float: none;
			max-width: none;
			margin: rem(11px) 0 rem(11px);
		}

		&.-small {
			@include bp-min(s) {
				max-width: rem(300px);
			}
		}

		&.-left {
			float: left;

			@include bp-min(s) {
				margin: rem(10px) rem(34px) rem(13px) 0;
			}
		}

		&.-profile {
			@extend .-small;
			border: 1px solid $color-input-border;

			@include bp-min(s) {
				margin-top: rem(-16px);
			}
		}
	}
}
