﻿.legal {
	position: relative;
	padding: 0;
	margin: 0 auto;
	overflow: hidden;
	color: #fff;

	&__list {
		@extend .no-bullets;
		overflow: hidden;
		margin: 0;

		&.-small {
			@include font-small;
			color: $color-legal;

			a {
				padding: rem(5px) 0;

				@include bp-min(s) {
					padding: 0;
				}
			}
		}
	}

	li {
		float: left;
		margin-right: rem(25px);
		text-transform: none;
		width: 100%;

		@include bp-min(s) {
			width: auto;
		}

		&.developer {
			margin: 0;

			@include bp-min(s) {
				float: right;
			}
		}

		&.company,
		&.developer {
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.04em;
		}
	}

	a {
		display: block;
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}
}
