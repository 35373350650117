﻿.pagination {
	@extend .no-bullets;
	margin: rem(30px) rem(-10px) 0;

	li {
		float: left;
		margin: 0 rem(10px);
	}

	a,
	span {
		@include font-hero-button;
		display: block;
		width: rem(50px);
		height: rem(50px);
		padding: rem(15px) 0 0;
	}

	a {
		transition: background 0.3s ease-in-out;
		background: $color-contrast;

		&:hover {
			background: darken($color-contrast, 10%);
		}
	}
}
