﻿.highlighted-overview {
	margin: 0 0 rem(4px);
}

.highlighted {
	position: relative;
	overflow: hidden;
	background: $color-contrast;
	padding: rem(22px) rem(30px) rem(21px);
	margin: 0 0 rem(10px);

	&.-image-left {
		min-height: rem(162px);
		padding: rem(22px) rem(30px) rem(21px);

		@include bp-min(s) {
			padding: rem(22px) rem(30px) rem(21px) calc(27% + 1.875rem);
		}
	}

	&__title {
		@include font-title-small;
		font-family: 700;
		margin: 0 0 rem(4px);
	}

	p {
		margin: 0;
	}

	&__list {
		overflow: hidden;
		padding: 0;
		margin: 0;

		dt {
			float: left;
			width: rem(114px);
		}

		dd {
			float: left;
			width: calc(100% - 114px);
			margin: 0;
		}
	}

	&__link {
		text-decoration: underline;

		&.-no-underline {
			text-decoration: none;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__image-left-container {
		display: block;
		height: rem(162px);
		margin: 0 0 rem(22px);
		background: #fff;

		@include bp-min(s) {
			position: absolute;
			width: 27%;
			height: 100%;
			margin: 0;
			top: 0;
			left: 0;
			border: 1px solid $color-input-border;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;

		&.-padding {
			padding: rem(10px);
		}
	}
}