﻿.header-content {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	height: 100%;
	padding: 2px 0 0;
	top: 0;
	left: rem(-9px);
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.04em;

	@include bp-min(m) {
		align-items: center;
		padding: 0;
	}

	&__title,
	&__login,
	&__logout {
		padding: 0 rem(9px);
		margin: 0 rem(7px) 0 0;
	}

	&__title {
		display: none;

		@include bp-min(xs) {
			display: block;
		}
	}

	&__login,
	&__logout {
		position: relative;
		padding: 0 0 0 rem(8px);

		&:before {
			@include icon($icon-login);
			position: absolute;
			font-size: rem(14px);
			top: rem(8px);
			left: rem(8px);
		}

		&:hover span:after {
			opacity: 1;
		}

		span {
			position: relative;
			padding: 0 0 0 rem(24px);

			@include bp-min(m) {

				&:after {
					position: absolute;
					content: '';
					width: 100%;
					height: 2px;
					bottom: 0;
					left: 0;
					background: #fff;
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}
			}
		}
	}

	&__logout {
		min-height: 0;
		border: none;
		text-transform: uppercase;
	}

	&__login:before {
		@include icon($icon-login);
	}

	&__logout:before {
		@include icon($icon-logout);
	}

	@include bp-min(m) {
		padding: 0 rem(144px) 0 0;
	}
}
