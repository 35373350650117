﻿.title {
	@include font-title;

	&.-small {
		@include font-title-small;
		font-family: 700;
		margin: 0;
	}

	&.-frontpage {
		margin: 0 0 rem(26px);
	}

	&__date {
		display: block;
		margin: rem(21px) 0 rem(-21px);
	}

	&.-profile {
		@extend .-small;
		font-weight: bold;
		margin: rem(22px) 0 rem(14px);
	}
}
