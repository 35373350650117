﻿.members {
	overflow: hidden;
	margin: 0 0 rem(21px);

	&.-slider {
		padding: rem(30px) 0 0;
		margin: 0;
		border-bottom: 1px solid $color-border;
	}

	&__wrapper {
		width: 100%;
		border-right: rem(25px) solid transparent;
		border-left: rem(25px) solid transparent;
	}

	.row {
		margin-right: rem(-28px);
		margin-left: rem(-28px);
	}

	.col-s-6 {
		border-right-width: rem(28px);
		border-left-width: rem(28px);
	}

	&__content {
		background: #fff;
		padding: rem(30px) rem(30px) 0;
	}

	&__link {
		@include responsive-container-pseudo(120, 78);
		display: block;
		background: #f7f8fa;
		margin: 0 0 rem(30px);
	}

	.swiper-slide {
		float: left;
		width: (100% / 8);
	}

	&__placeholder {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #fff url(../../images/placeholder.png) no-repeat center;
		background-size: contain;
	}
}
