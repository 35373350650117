﻿label {
	margin: 0 0 1px;

	p {
		margin: 0;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

input,
textarea {
	margin: 0 0 rem(11px);
	border-color: $color-input-border;
}

textarea {
	min-height: rem(310px);

	&.small {
		min-height: rem(112px);
	}

	.form-registration & {
		min-height: rem(398px);
	}
}

.umbraco-forms-field.email2 {
	display: none !important;
}