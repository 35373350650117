﻿.person {
	position: relative;
	padding: 0 0 0 rem(170px);
	min-height: rem(200px);

	&__function,
	&__address,
	&__telephone,
	&__email {
		display: block;
	}

	&__function {
		margin: 0 0 rem(29px);
	}

	&__email  {
		text-decoration: underline;
	}

	&__image-container {
		position: absolute;
		margin: 0;
		top: rem(8px);
		left: 0;
	}
}