﻿.fileupload {

	input {
		width: 0.1px !important;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
		padding: 0;
	}

	input + label {
		width: 100% !important;
		position: relative;
		height: rem(46px);

		@include bp-min(m) {
			//display: flex;
		}

		span {
			margin: 0 rem(5px);
		}

		&:before {
			@extend .button;
			@extend .-secondary;
			content: attr(data-caption);
			/*//@include icon($icon-arrow-bold-right);
			position: absolute;
			z-index: 1;
			//color: color('secondary');
			font-size: rem(12px);
			bottom: rem(21px);
			right: rem(20px);

			@include bp-min(m) {
				bottom: rem(15px);
			}*/
		}

		&:after {
			/*display: block;
			
			background: #fff;
			color: $color-primary;
			width: 100%;
			height: rem(40px);
			padding: rem(6px) rem(60px) rem(8px) rem(20px);
			margin: rem(8px) 0 0;
			transition: all 0.3s ease-in-out;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include bp-min(m) {
				position: absolute;
				width: calc(100% - 200px);
				margin: 0;
				top: 0;
				right: 0;
			}*/
		}
	}

	input:focus + label,
	input + label:hover {

		&:after {
			//background: lighten(color('secondary'), 30%);
			//color: $white;
		}
	}
}
