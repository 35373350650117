﻿@font-face {
	font-family: 'Rajdhani';
	src: url('../../../fonts/Rajdhani-Medium.woff2') format('woff2'), url('../../../fonts/Rajdhani-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Rajdhani';
	src: url('../../../fonts/Rajdhani-SemiBold.woff2') format('woff2'), url('../../../fonts/Rajdhani-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Rajdhani';
	src: url('../../../fonts/Rajdhani-Bold.woff2') format('woff2'), url('../../../fonts/Rajdhani-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@mixin font-text {
	font-family: $base-font-family;
	font-size: rem(20px);
	line-height: rem(30px);

	@media only screen and (min-width: 432px) {
		font-size: rem(19px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(18px);
	}
}

@mixin font-small {
	font-size: rem(16px);
	line-height: rem(20px);
}

@mixin font-hero-button {
	font-size: rem(20px);
	line-height: rem(20px);
	letter-spacing: 0.04em;
}

@mixin font-title {
	font-size: rem(32px);
	line-height: rem(30px);
	font-weight: 600;
}

@mixin font-title-small {
	font-size: rem(20px);
	line-height: rem(24px);
}

@mixin font-larger {
	font-size: rem(20px);
	line-height: rem(30px);
}

@mixin font-call-to-action {
	font-size: rem(24px);
	line-height: rem(30px);
}