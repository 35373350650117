﻿.video {
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 0;
	width: 100%;

	figure {
		background: $color-primary;
	}

	img {
		opacity: 0.5;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&__play {
		position: absolute;
		overflow: hidden;
		z-index: 1;
		width: rem(100px);
		height: rem(70px);
		border-radius: rem(14px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		color: $color-primary;
		transition: all 0.3s ease-in-out;

		&:before {
			position: absolute;
			content: '';
			z-index: -1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			//background: $color-primary-hover;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 0.3s ease-in-out;
		}

		&:after {
			@include icon($icon-play);
			position: absolute;
			margin: rem(-14px) 0 0 rem(-14px);
			top: 50%;
			left: 50%;
			font-size: rem(28px);
		}
	}

	&:hover,
	&:focus,
	&:active {

		.video__play {
			background: $color-primary;
			color: #fff;

			&:before {
				transform: scaleX(1);
			}
		}
	}
}
