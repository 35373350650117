﻿.hero {
	position: relative;
	height: rem(200px);

	.frontpage & {
		height: rem(300px);

		@include bp-min(t) {
			height: rem(400px);
		}

		@include bp-min(m) {
			height: rem(500px);
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__button {
		@include font-hero-button;
		position: absolute;
		background: $color-secondary;
		color: #fff;
		width: rem(198px);
		padding: rem(21px) rem(50px) rem(19px) rem(10px);
		right: 0;
		bottom: 0;
		text-transform: uppercase;
		text-align: right;
		font-weight: bold;

		&:before {
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 rem(80px) rem(72px);
			border-color: transparent transparent $color-secondary transparent;
			top: 0;
			left: rem(-72px);
		}

		&:after {
			@include icon($icon-arrow-right);
			position: absolute;
			font-size: rem(17px);
			top: 50%;
			right: rem(16px);
			transform: translateY(-50%);
			transition: right 0.2s ease-in-out;
		}

		&:hover:after {
			right: rem(12px);
		}
	}
}
