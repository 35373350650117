﻿.layout {
	overflow: hidden;

	@include bp-min(m) {
		display: flex;
	}

	&__main {
		padding: 0 rem(20px);

		@include bp-min(s) {
			padding: 0 rem(50px) 0 rem(50px);
		}

		@include bp-min(m) {
			float: left;
			width: calc(100% - 307px);
		}

		@include bp-min(l) {
			width: calc(100% - 407px);
		}

		@include bp-min(xl) {
			width: calc(100% - 507px);
			padding: 0 rem(50px) 0 rem(100px);
		}
	}

	&__aside {
		padding: rem(50px) rem(20px);
		background: $color-contrast;

		@include bp-min(m) {
			float: left;
			width: rem(307px);
			padding: rem(130px) rem(20px) rem(50px) rem(20px);
		}

		@include bp-min(l) {
			width: rem(407px);
			padding: rem(130px) rem(50px) rem(50px) rem(50px);
		}

		@include bp-min(xl) {
			width: rem(507px);
			padding: rem(130px) rem(100px) rem(50px) rem(50px);
		}
	}
}
