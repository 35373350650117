﻿.event-intro {
	@include font-larger;
	background: $color-contrast;
	padding: rem(20px) rem(30px) rem(21px);
	margin: 0 0 rem(39px);

	&__text {
		margin: 0;

		&.-accent {
			color: $color-secondary;
		}
	}
}
