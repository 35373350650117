﻿.news-message {
	display: block;
	background: $color-contrast;
	margin: 0 0 rem(10px);
	transition: background 0.2s ease-in-out;

	.frontpage & {
		background: $color-contrast-secondary;
		color: $color-secondary;
	}

	&.-first {
		background: $color-primary;
		color: #fff;

		.news-message__title.-small {
			margin: 0 0 rem(12px);
		}

		.frontpage & {
			background: $color-secondary;
		}
	}

	&:hover {
		background: darken($color-contrast, 5%);

		.frontpage & {
			background: darken($color-contrast-secondary, 5%);
		}

		&.-first {
			background: darken($color-primary, 5%);

			.frontpage & {
				background: darken($color-secondary, 5%);
			}
		}
	}

	&__content {
		padding: rem(14px) rem(32px) rem(15px);
	}

	.news-message__title.-small {
		font-weight: 700;
		margin: 0 0 rem(9px);
	}

	&__date {
		@include font-small;
		display: block;
		margin: rem(-9px) 0 0;
	}

	&__image {
		width: 100%;
		height: auto;
	}
}
