﻿.checkbox {
	display: inline-block;
	overflow: hidden;

	input {
		display: none;

		&:checked ~ label {

			&:after {
				opacity: 1;
			}
		}
	}

	> label {
		position: relative;
		display: inline-block;
		width: auto !important;
		padding: rem(8px) 0 rem(8px) rem(30px);
		margin: 0 rem(32px) 0 0;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: rem(20px);
			height: rem(20px);
			border: 1px solid $color-input-border;
			top: rem(14px);
			left: 0;
			text-align: center;
			color: transparent;
			background: #fff;
		}

		&:after {
			position: absolute;
			content: '';
			width: rem(12px);
			height: rem(12px);
			top: rem(18px);
			left: rem(4px);
			background: $color-primary;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
		}

		&:hover:after {
			opacity: 0.4;
		}
	}
}
