﻿.breadcrumbs {
	@extend .no-bullets;
	font-size: rem(16px);
	font-weight: 600;
	overflow: hidden;
	padding: 0;
	margin: rem(5px) rem(-5px) rem(28px);

	li {
		float: left;
		margin: rem(5px) rem(5px) rem(5px) rem(5px);
		opacity: 0.5;
		color: $color-primary;

		+ li {
			position: relative;
			margin-left: rem(16px);

			&:before {
				@include icon($icon-arrow-right);
				position: absolute;
				top: rem(11px);
				left: rem(-15px);
				font-size: rem(7px);
			}
		}
	}

	a {
		position: relative;
		color: inherit;
		text-decoration: none;


		&:hover {
			text-decoration: underline;
		}
	}
}
