﻿.content-block {
	@extend .clearfix;
	position: relative;
	padding: rem(43px) 0 rem(36px);
	border-bottom: 1px solid $color-border;

	&:last-of-type {
		padding: rem(43px) 0 rem(86px);
		border-bottom: none;
	}
}

.title + .content-block {
	padding-top: rem(22px);
}
