﻿.agenda-container {
	position: relative;
	margin: 0 0 rem(9px);

	@include bp-min(s) {
		padding: 0 0 0 rem(50px);
	}

	&__date {
		margin: 0;

		@include bp-min(s) {
			position: absolute;
			width: rem(20px);
			top: rem(20px);
			left: 0;
		}
	}

	&__date-day,
	&__date-number,
	&__date-month {
		font-weight: 700;
		display: block;
		text-transform: uppercase;

		@include bp-min(s) {
			text-align: center;
		}
	}

	&__date-day {
		font-size: rem(16px);
	}

	&__date-number {
		font-size: rem(26px);
		margin: rem(-12px) 0 rem(-15px);
	}

	&__date-month {
		font-size: rem(13px);
	}
}

.agenda-item {
	position: relative;
	background: $color-contrast;
	padding: rem(15px) rem(30px) rem(14px) rem(30px);
	margin: 0 0 1px;

	@include bp-min(s) {
		padding: rem(15px) rem(197px) rem(14px) rem(30px);
	}

	&__event {
		@include font-small;
		font-weight: 600;
		margin: 0 0 rem(7px);
		display: block;
		text-transform: uppercase;
		letter-spacing: 0.04em;
	}

	&__title {
		@include font-title-small;
		font-weight: 700;
		margin: 0 0 rem(-4px);
	}

	&__time {
		@include font-title-small;
	}

	&__image-container {
		height: 100%;
		margin: 0 0 rem(10px);

		@include bp-min(s) {
			position: absolute;
			width: rem(167px);
			margin: 0;
			top: 0;
			right: 0;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}