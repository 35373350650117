﻿.call-to-action {
	@include font-title-small;
	position: relative;
	overflow: hidden;
	z-index: 0;
	padding: rem(104px) rem(30px) rem(1px);
	background-size: cover;
	color: $color-primary;
	background: #fff;
	line-height: rem(30px);

	&:before {
		position: absolute;
		content: '';
		width: rem(100px);
		height: rem(84px);
		top: rem(30px);
		left: 50%;
		background: url(../../images/logo-bc-s-gravenzande.png) no-repeat center;
		background-image: url(../../images/logo-bc-s-gravenzande.svg), none;
		transform: translateX(-50%);
	}

	&.-blue {
		color: #fff;
		background: url(../../images/call-to-action-background-blue.jpg) no-repeat;
		background-size: cover;
	}

	&.-red {
		color: #fff;
		background: url(../../images/call-to-action-background-red.jpg) no-repeat;
		background-size: cover;
	}

	&.-aside {
		margin: 0 0 rem(50px);
	}

	&.-large {
		display: flex;
		align-items: center;
		min-height: rem(182px);
		margin: rem(7px) 0 rem(13px);

		.frontpage & {
			margin: 0 0 rem(50px);

			&:last-child {
				margin: 0 0 rem(10px);
			}
		}

		@include bp-min(s) {
			@include font-call-to-action;
			padding: rem(22px) rem(70px) rem(21px) rem(200px);

			&:before {
				width: rem(141px);
				height: rem(120px);
				top: 50%;
				left: rem(30px);
				transform: translateY(-50%);
			}

			&.-red {
				padding: rem(22px) rem(368px) rem(21px) rem(30px);

				&:before {
					width: rem(322px);
					height: rem(284px);
					left: auto;
					right: rem(-10px);
					background: url(../../images/logo-bc-s-gravenzande-tilted.png) no-repeat center;
					background-image: url(../../images/logo-bc-s-gravenzande-tilted.svg), none;

					@include bp-min(xxl) {
						right: rem(30px);
					}
				}
			}
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	strong {
		@include font-title;
	}
}
