﻿.home-layout {
	padding: 0 rem(0) rem(50px);

	@include bp-min(xs) {
		padding: 0 rem(15px) rem(50px);
	}

	@include bp-min(s) {
		padding: 0 rem(30px) rem(50px);
	}

	@include bp-min(l) {
		padding: 0 rem(45px) rem(50px);
	}

	@include bp-min(xl) {
		display: flex;
		flex-wrap: wrap;
		padding: 0 rem(60px) rem(50px);
	}

	@include bp-min(xl) {
		padding: 0 rem(75px) rem(50px);
	}

	&__item {
		overflow: hidden;
		border-left: rem(25px) solid transparent;
		border-right: rem(25px) solid transparent;
		padding: 0 0 rem(40px);

		&.-has-title {
			padding: rem(46px) 0 rem(50px);
		}

		@include bp-min(xl) {
			flex: 1;
			min-width: 50%;
		}
	}
}
