.text {

	p,
	ul,
	ol,
	dl {
		margin: 0 0 rem(30px);

		&:last-child {
			margin-bottom: rem(4px);
		}
	}

	p + ul,
	p + ol {
		margin-top: rem(-30px);
	}

	ul,
	ol {
		@extend .no-bullets;
		overflow: hidden;

		li {
			position: relative;
			padding: 0 0 0 rem(16px);

			&:before {
				position: absolute;
				content: '';
				width: rem(5px);
				height: rem(5px);
				border-radius: 50%;
				top: rem(12px);
				left: 0;
				background: $color-primary;
			}
		}
	}


	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	table {
		display: inline-block;
		margin: 0;

		td {
			width: auto !important;
			padding: 0;

			&:first-child {
				padding-right: rem(12px);
			}
		}
	}

	+ .buttons {
		margin-top: rem(27px);
		margin-bottom: rem(22px);
	}

	+ .highlighted-overview {
		margin: rem(21px) 0 0;
	}
}
