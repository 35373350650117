﻿.nav-main {

	@include bp-min(m) {
		position: absolute;
		top: 0;
		right: rem(-14px);
		width: 50%;
		padding: 0 0 0 rem(144px);
	}

	ul {
		@extend .no-bullets;
		@extend .clearfix;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: center;
		height: rem(100px);
		margin: 0;
	}


	li {
		width: 100%;

		@include bp-min(m) {
			float: left;
			width: auto;
		}

		&.active a {
			background: mix($color-primary, #fff, 65%);

			@include bp-min(m) {
				background: none;

				span:before {
					opacity: 1;
				}
			}
		}
	}

	a {
		display: block;
		color: #fff;
		text-decoration: none;
		padding: rem(10px) rem(14px);
		text-transform: uppercase;
		border-bottom: 1px solid rgba(#fff, 0.7);
		letter-spacing: 0.04em;

		@include bp-min(m) {
			border-bottom: none;
		}

		&:hover {
			background: mix($color-primary, #fff, 80%);

			@include bp-min(m) {
				background: none;
				padding: 0 rem(14px);

				span:before {
					opacity: 1;
				}
			}
		}
	}

	span {
		position: relative;

		@include bp-min(m) {

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 2px;
				bottom: 0;
				left: 0;
				background: #fff;
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
			}
		}
	}
}
