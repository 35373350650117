﻿.contacts {
	margin: rem(11px) 0 rem(14px);
}

.contact {
	position: relative;
	display: flex;
	align-items: center;
	background: $color-contrast;
	min-height: rem(80px);
	padding: 0 0 0 rem(120px);
	margin: 0 0 rem(10px);

	&__image {
		position: absolute;
		width: rem(60px);
		height: rem(60px);
		top: rem(10px);
		left: rem(30px);
		border-radius: 50%;
	}

	&__title {
		@include font-text;
		font-weight: 500;
		min-width: 50%;
		margin: 0;
	}

	&__members__link {
		font-weight: bold;
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}