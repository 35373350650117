﻿.vsummary {
	background: mix(red, #fff, 20%);
	padding: rem(9px) rem(9px) rem(3px);
	border: 1px solid mix(red, #fff, 40%);
	margin: rem(24px) 0 rem(20px);
	max-width: rem(485px);

	p,
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 rem(8px);

		&:last-child {
			margin: 0 0 rem(8px);
		}
	}
}
