﻿.button,
input[type="submit"] {
	display: inline-block;
	font-weight: 600;
	color: #fff;
	overflow: hidden;
	min-height: 0; // Using line-height instead
	padding: rem(7px) rem(18px) rem(7px);
	background: $color-primary;
	vertical-align: middle;
	transition: all 0.2s ease-out;
	transform: perspective(1px) translateZ(0); // Improve performance on mobile/tablet devices // Perspective reduces blurryness of text in Chrome
	box-shadow: 0 0 1px rgba(0, 0, 0, 0); // Improve aliasing on mobile/tablet devices

	&:hover,
	&:focus,
	&:active {
		background: darken($color-primary, 10%);
	}

	&.-secondary {
		background: $color-secondary;

		&:hover,
		&:focus,
		&:active {
			background: darken($color-secondary, 10%);
		}
	}
}

.buttons {
	margin: rem(-5px) rem(-10px);

	.agenda-item & {
		margin: rem(6px) rem(-10px) rem(4px);
	}

	.button,
	strong {
		margin: rem(5px) rem(10px)
	}

	strong {
		display: inline-block;
	}
}