﻿.form-wrapper {
	@extend .clearfix;
	padding: rem(24px) rem(30px) rem(30px);
	margin: rem(11px) 0 rem(14px);
	background: $color-contrast;

	&.-last div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&.-login {
		max-width: rem(485px);
	}

	&__side {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
