﻿.logo {
	position: absolute;
	overflow: hidden;
	z-index: 1;
	display: block;
	width: rem(163px);
	height: rem(138px);
	top: rem(35px);
	left: 50%;
	transform: translateX(-50%);
	text-indent: -9999px;
	background: url(../../images/logo-bc-s-gravenzande.png) no-repeat center;
	background-image: url(../../images/logo-bc-s-gravenzande.svg), none;

	&.-footer {
		top: rem(-64px)
	}
}
